import * as Types from '@haaretz/s-fragments/Types';

import { MultiTypeMarketingElementFragmentGql } from '@haaretz/s-fragments/MultiTypeMarketingElement';
import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragmentGql } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { MultiTypeMarketingElementFragment } from '@haaretz/s-fragments/MultiTypeMarketingElement';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
export type RainbowToolFragment = (
  { slotId?: string | null, toolId: string, testGroup?: string | null, metaData?: (
    { entries: Array<(
      { key: string, value: string }
      & { __typename: 'MapEntry' }
    )> }
    & { __typename: 'Map' }
  ) | null, element: (
    MultiTypeMarketingElementFragment
    & { __typename: 'MultiTypeMarketingElement' }
  ) | { __typename: 'OnboardingElement' } }
  & { __typename: 'RainbowTool' }
);


export type RainbowToolFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const RainbowToolFragmentGql = `
    fragment RainbowToolFragment on RainbowTool {
  __typename
  slotId
  toolId
  testGroup
  metaData {
    __typename
    entries {
      __typename
      key
      value
    }
  }
  element {
    __typename
    ... on MultiTypeMarketingElement {
      ...MultiTypeMarketingElementFragment
    }
  }
}
    `;