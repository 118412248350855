'use client';
import border from '@haaretz/l-border.macro';
import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import radius from '@haaretz/l-radius.macro';
import space from '@haaretz/l-space.macro';
import zIndex from '@haaretz/l-z-index.macro';
import usePageType from '@haaretz/s-atoms/pageType';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import useBreakpoint from '@haaretz/s-use-breakpoint';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import * as React from 'react';
import s9 from 'style9';

import useRainbowBiAction from '../../../../utils/useRainbowBiAction';
import RainbowImpression from '../../RainbowImpression';

import type { MultiTypeMarketingElementServerProps } from '../../';

const c = s9.create({
  base: {
    position: 'fixed',
    zIndex: zIndex('above'),
    bottom: 0,
    insetInlineEnd: 0,
    backgroundColor: color('primary200'),
    width: '100%',
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
    borderTopColor: color('neutral500'),
    transitionDuration: '0.25s',
    transitionProperty: 'maxHeight',
    transitionTimingFunction: 'ease-in',
    ...border({
      color: fork({ default: color('neutral500'), tm: color('neutral600') }),
      spacing: 1,
      style: 'solid',
      width: '1px',
      side: 'bottom',
    }),
  },
  sectionSpacing: {
    ...merge(
      mq({
        until: 's',
        value: {
          bottom: space(13),
          paddingBottom: space(4),
        },
      })
    ),
  },
  baseAcquisition: {
    height: space(45),
    ...merge({
      ...mq({ from: 's', value: { height: space(50) } }),
      ...mq({ from: 'l', value: { height: space(49) } }),
      ...mq({ from: 'xl', value: { height: space(54) } }),
    }),
  },

  baseOpen: {
    maxHeight: '50vh',
  },

  baseSlim: {
    maxHeight: space(20),
    ...merge({
      ...mq({ from: 's', value: { maxHeight: space(15) } }),
      ...mq({ from: 'l', value: { maxHeight: space(17) } }),
    }),
  },

  subscribersTheme: {
    minHeight: space(20),
    ...merge({
      ...mq({ from: 's', value: { minHeight: space(15) } }),
      ...mq({ from: 'l', value: { minHeight: space(17) } }),
      ...mq({ from: 'xl', value: { minHeight: space(17) } }),
    }),
  },

  fade: {
    transitionProperty: 'opacity',
    transitionTimingFunction: 'ease-in-out',
  },

  fadeOut: {
    opacity: 0,
    transitionDuration: '0.15s',
  },

  fadeIn: {
    opacity: 1,
    transitionDuration: '0.25s',
    transitionDelay: '0.1s',
  },
  wrapper: {
    paddingBottom: space(6),
    paddingTop: space(6),
    ...merge({
      ...mq({
        from: 'l',
        value: { paddingBottom: space(9), paddingTop: space(9) },
      }),
      ...mq({
        from: 'xl',
        value: { paddingBottom: space(10), paddingTop: space(10) },
      }),
    }),
  },
  wrapperSlim: {
    // paddingBottom: space(4),
    // paddingTop: space(4),
    display: 'flex',
    alignItems: 'center',
    ...merge({
      ...mq({
        from: 'l',
        value: { paddingBottom: space(3), paddingTop: space(5) },
      }),
      ...mq({
        from: 'xl',
        value: { paddingBottom: space(5), paddingTop: space(4) },
      }),
    }),
  },
  controlButton: {
    position: 'absolute',
    top: space(0.25),
    insetInlineEnd: space(0.25),
    ...merge({
      ...mq({
        from: 's',
        value: {
          top: space(3),
          insetInlineEnd: space(2),
        },
      }),
      ...mq({
        from: 'l',
        value: {
          top: space(2),
          insetInlineEnd: space(2),
        },
      }),
    }),
  },
  closeIcon: {
    rotate: '90deg',
  },
  chevronIcon: {
    fontSize: space(5),
    rotate: '-90deg',
    ...merge({
      ...mq({
        from: 's',
        value: {
          fontSize: space(6),
        },
      }),
    }),
  },

  iconWrap: {
    width: space(5),
    height: space(5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: radius('circle'),
    ...border({
      color: fork({ default: color('neutral500'), tm: color('neutral600') }),
      spacing: 1,
      style: 'solid',
      width: '1px',
      side: 'all',
    }),
  },

  crossIcon: {
    fontSize: space(3),
    backgroundColor: 'inherit',
    ...merge({
      ...mq({
        from: 's',
        value: {
          fontSize: space(6),
        },
      }),
    }),
  },
  crossButton: {
    position: 'absolute',
    top: space(-5.5),
    insetInlineEnd: space(0.5),
    ...merge({
      ...mq({
        until: 's',
        value: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
      }),
      ...mq({
        from: 's',
        value: {
          display: 'block',
          top: space(0.5),
          insetInlineEnd: space(-0.5),
        },
      }),
    }),
  },
  contentisSubscribers3: {
    color: fork({ default: color('neutral1200'), tm: color('neutral100') }),
  },
  contentSubscribers1: {
    color: fork({ default: color('textDarkAllModes'), tm: color('neutral1200') }),
  },
  backgroundTheme1: {
    backgroundColor: color('rainbowBottomRulerBgMobile1'),
    ...merge({
      ...mq({
        from: 's',
        value: { backgroundColor: color('rainbowBottomRulerBg1') },
      }),
    }),
  },
  backgroundTheme2: {
    backgroundColor: color('rainbowBottomRulerBgMobile2'),
    ...merge({
      ...mq({
        from: 's',
        value: { backgroundColor: color('rainbowBottomRulerBg2') },
      }),
    }),
  },
  backgroundTheme3: {
    backgroundColor: color('rainbowBottomRulerBgMobile3'),
    ...merge({
      ...mq({
        from: 's',
        value: { backgroundColor: color('rainbowBottomRulerBg3') },
      }),
    }),
  },
  backgroundSale1: {
    backgroundImage: `linear-gradient(to bottom, ${color(
      'rainbowBottomRulerSale1GradStart'
    )}, ${color('rainbowBottomRulerSale1GradEnd')})` as string,
  },
  wrapperSubscribers: {
    ...merge({
      ...mq({
        until: 's',
        value: { justifyContent: 'start' },
      }),
    }),
  },
  backgroundTheme4: {
    backgroundColor: color('rainbowBottomRulerBgMobile4'),
    ...merge({
      ...mq({
        from: 's',
        value: { backgroundColor: color('rainbowBottomRulerBg4') },
      }),
    }),
  },
});

const mapTheme = {
  htzBottomAcquisition1: c.backgroundTheme1,
  tmBottomAcquisition1: c.backgroundTheme1,
  htzBottomAcquisition2: c.backgroundTheme1,
  tmBottomAcquisition2: c.backgroundTheme1,
  htzBottomAcquisition3: c.backgroundTheme1,
  tmBottomAcquisition3: c.backgroundTheme1,
  htzBottomAcquisition4: c.backgroundTheme1,
  tmBottomAcquisition4: c.backgroundTheme1,
  htzBottomSubscribers1: c.backgroundTheme2,
  tmBottomSubscribers1: c.backgroundTheme2,
  htzBottomSubscribers2: c.backgroundTheme3,
  tmBottomSubscribers2: c.backgroundTheme3,
  htzBottomSubscribers3: c.backgroundTheme4,
  tmBottomSubscribers3: c.backgroundTheme4,
  htzSlimBottomSubscribers1: c.backgroundTheme3,
  tmSlimBottomSubscribers1: c.backgroundTheme3,
  htzBottomSale1: c.backgroundSale1,
  tmBottomSale1: c.backgroundSale1,
};

type SkinType = keyof typeof mapTheme;

export interface PaywallBottomRulerClientProps extends MultiTypeMarketingElementServerProps {
  children: [React.ReactNode, React.ReactNode, React.ReactNode];
}

export default function PaywallBottomRulerClient({
  styleExtend = [],
  children,
  ...marketingProps
}: PaywallBottomRulerClientProps) {
  const {
    type,
    skin = 'htzBottomAcquisition1',
    featureType,
    featureName,
    campaignName,
    campaignDetails,
    metaData,
    mobileImage,
    image,
    testGroup,
    declineBehavior,
    declineCodeNumber,
  } = marketingProps;
  const isSlim = type === 'NarrowBottomStrip';

  const breakpoint = useBreakpoint();

  const [isOpen, setIsOpen] = React.useState(!isSlim);
  const [isFading, setIsFading] = React.useState(false);
  const [isDecline, setIsDecline] = React.useState(false);

  const pageType = usePageType();

  const isSection = pageType && ['Homepage', 'Section'].includes(pageType);

  const doBiDecline = useRainbowBiAction({
    campaignDetails: campaignDetails ?? undefined,
    actionCodeNumber: declineCodeNumber ?? undefined,
    campaignName: campaignName ?? undefined,
    featureName: featureName ?? undefined,
    featureType: featureType ?? undefined,
    featureTheme: skin ?? undefined,
    metaData,
    testGroup,
  });

  if (isDecline) {
    return null;
  }

  const isSubscribers3 = skin === 'tmBottomSubscribers3' || skin === 'htzBottomSubscribers3';
  const isSubscribers2 = skin === 'tmBottomSubscribers2' || skin === 'htzBottomSubscribers2';
  const isSubscribers1 = skin === 'tmBottomSubscribers1' || skin === 'htzBottomSubscribers1';
  const isCampaign = isSubscribers2 || isSubscribers3 || isSubscribers1;

  const isSkinSubscribers =
    skin === 'htzSlimBottomSubscribers1' || skin === 'tmSlimBottomSubscribers1';

  const isCampaignNoImageMobile =
    isCampaign && !mobileImage && image && breakpoint && ['default'].includes(breakpoint);
  const isSlimSubscribers = (isSlim && isSkinSubscribers) || isCampaignNoImageMobile;

  let typeBehavior = declineBehavior ?? 'hide';

  if (isSlim) {
    typeBehavior = typeBehavior === 'minimize' ? 'hide' : typeBehavior;
  }

  const onDecline = () => {
    doBiDecline && doBiDecline();
  };

  const themeBgStyles = !!skin && mapTheme[skin as SkinType];
  return (
    <div
      className={s9(
        c.base,
        isSection && c.sectionSpacing,
        isSlimSubscribers ? c.subscribersTheme : isOpen ? c.baseOpen : c.baseSlim,
        !isSlimSubscribers && c.baseAcquisition,
        themeBgStyles,
        isSubscribers3 && c.contentisSubscribers3,
        isSubscribers1 && c.contentSubscribers1,
        isSlimSubscribers && c.wrapperSubscribers,
        ...styleExtend
      )}
    >
      <RainbowImpression
        featureName={featureName ?? undefined}
        featureType={featureType ?? undefined}
        featureTheme={skin ?? undefined}
        campaignName={campaignName ?? undefined}
        campaignDetails={campaignDetails ?? undefined}
        metaData={metaData}
        testGroup={testGroup}
      />

      {typeBehavior === 'minimize' && isOpen && (
        <ClickArea
          rippleSize="small"
          styleExtend={[c.controlButton]}
          title={fork({ default: 'הקטנה', hdc: 'Minimize' })}
          onClick={() => {
            setIsFading(p => !p);
            setTimeout(() => {
              setIsOpen(p => !p);
            }, 250);
          }}
        >
          <Icon icon="chevron" styleExtend={[c.chevronIcon, isOpen && c.closeIcon]} />
          <VisuallyHidden>{fork({ default: 'הקטנה', hdc: 'Minimize' })}</VisuallyHidden>
        </ClickArea>
      )}
      {typeBehavior === 'hide' && (
        <ClickArea
          size="large"
          disableRipple
          styleExtend={[c.crossButton]}
          onClick={() => {
            onDecline();
            setIsDecline(true);
          }}
          title={fork({ default: 'סגירה', hdc: 'Close' })}
        >
          {breakpoint === 'default' ? (
            <div className={s9(c.iconWrap, themeBgStyles)}>
              <Icon icon="close" styleExtend={[c.crossIcon]} />
            </div>
          ) : (
            <Icon icon="close" styleExtend={[c.crossIcon]} />
          )}
          <VisuallyHidden>{fork({ default: 'סגירה', hdc: 'Close' })}</VisuallyHidden>
        </ClickArea>
      )}

      {isSlimSubscribers ? (
        <div className={s9(c.wrapperSlim)}>{children[2]}</div>
      ) : isOpen ? (
        <div className={s9(c.fade, c.wrapper, isFading ? c.fadeOut : c.fadeIn)}>{children[0]}</div>
      ) : (
        <div className={s9(c.fade, c.wrapperSlim, !isSlim && (isFading ? c.fadeIn : c.fadeOut))}>
          {children[1]}
        </div>
      )}
    </div>
  );
}
