'use client';

import useArticleId from '@haaretz/s-atoms/articleId';
import usePlatform from '@haaretz/s-atoms/platform';
import { BreakingNewsNotificationFragment } from '@haaretz/s-fragments/BreakingNewsNotification';
import { HideSiblingsContext } from '@haaretz/s-hide-siblings-on-demand';
import { useBreakingNewsNotificationsPersonalQuery } from '@haaretz/s-queries/BreakingNewsNotification';
import * as React from 'react';

import BreakingNewsNotification from './BreakingNewsNotification';

type NotificationStateType = BreakingNewsNotificationFragment | null;

export interface BreakingNewsQueringProps {
  id: string;
}

const POLL_INTERVAL = 30000;
const LAST_POLL_KEY = 'bnLastPoll';

const IS_CLIENT = typeof window !== 'undefined';

function setLastPoll(value: number): void {
  IS_CLIENT && window.localStorage.setItem(LAST_POLL_KEY, `${value}`);
}

function getLastPoll(): number {
  const lastPollVal = IS_CLIENT && window?.localStorage.getItem(LAST_POLL_KEY);
  let lastPoll;

  if (lastPollVal) {
    lastPoll = parseInt(lastPollVal, 10);
  } else {
    lastPoll = Date.now() - 3600000; // hour ago
  }

  return lastPoll;
}

function BreakingNewsQuering({ id }: BreakingNewsQueringProps) {
  const [notification, setNotification] = React.useState<NotificationStateType>(null);
  const fromTime = React.useMemo(getLastPoll, [notification]);
  const articleId = useArticleId();
  const { data } = useBreakingNewsNotificationsPersonalQuery(
    { fromTime },
    { enabled: !!fromTime, suspense: false, refetchInterval: POLL_INTERVAL }
  );

  const setOnlyVisibleChildId = React.useContext(HideSiblingsContext);

  React.useEffect(() => {
    const candidate = data?.BreakingNewsNotifications?.items?.[0];

    if (candidate) {
      if (candidate?.isOffline) {
        setNotification(null);
      } else if (candidate && !(articleId && candidate.url?.includes(articleId))) {
        setNotification(candidate);
        setLastPoll(candidate.creationDateTime);
        setOnlyVisibleChildId && setOnlyVisibleChildId(id);
      }
    }
  }, [articleId, data, id, setOnlyVisibleChildId]);

  return notification ? (
    <BreakingNewsNotification
      {...notification}
      articleId={articleId}
      onClose={() => setOnlyVisibleChildId && setOnlyVisibleChildId(null)}
    />
  ) : null;
}

// Breaking-news is available for web-app only
export default function WebAppBreakingNewsQuering(props: BreakingNewsQueringProps) {
  const platform = usePlatform();

  return platform !== 'app' ? <BreakingNewsQuering {...props} /> : null;
}
