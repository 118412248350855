import * as Types from '@haaretz/s-fragments/Types';

import { HtmlNodeFragmentGql } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragmentGql } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragmentGql } from '@haaretz/s-fragments/ImageCrop';
import { HtmlNodeFragment } from '@haaretz/s-fragments/HTMLNode';
import { ImageFragment } from '@haaretz/s-fragments/HTZ_image_Image';
import { ImageCropFragment } from '@haaretz/s-fragments/ImageCrop';
export type MultiTypeMarketingElementFragment = (
  { id?: string | null, actionCodeNumber?: number | null, actionText?: string | null, actionName?: Types.BiActionNameType | null, actionType?: Types.BiActionType | null, actionUrl?: string | null, campaignDetails?: string | null, campaignName?: string | null, declineBehavior?: string | null, declineCodeNumber?: number | null, declineText?: string | null, declineUrl?: string | null, featureName?: string | null, featureType?: string | null, hideOnSite?: boolean | null, mobileActionCodeNumber?: number | null, mobileActionName?: Types.BiActionNameType | null, mobileActionText?: string | null, mobileActionType?: Types.BiActionType | null, mobileActionUrl?: string | null, mobileDeclineCodeNumber?: number | null, mobileDeclineText?: string | null, mobileDeclineUrl?: string | null, mobileTextLine1?: string | null, mobileTextLine2?: string | null, name?: string | null, skin?: string | null, textLine1?: string | null, textLine2?: string | null, type?: string | null, htmlContent?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null, image?: (
    ImageFragment
    & { __typename: 'htz_image_Image' }
  ) | null, mobileHtmlContent?: Array<{ __typename: 'Enhancement' } | (
    HtmlNodeFragment
    & { __typename: 'HtmlNode' }
  )> | null, mobileImage?: (
    ImageFragment
    & { __typename: 'htz_image_Image' }
  ) | null }
  & { __typename: 'MultiTypeMarketingElement' }
);


export type MultiTypeMarketingElementFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const MultiTypeMarketingElementFragmentGql = `
    fragment MultiTypeMarketingElementFragment on MultiTypeMarketingElement {
  __typename
  id
  actionCodeNumber
  actionText
  actionName
  actionType
  actionUrl
  campaignDetails
  campaignName
  declineBehavior
  declineCodeNumber
  declineText
  declineUrl
  featureName
  featureType
  hideOnSite
  htmlContent {
    ...HtmlNodeFragment
  }
  image {
    ...ImageFragment
  }
  mobileActionCodeNumber
  mobileActionName
  mobileActionText
  mobileActionType
  mobileActionUrl
  mobileDeclineCodeNumber
  mobileDeclineText
  mobileDeclineUrl
  mobileHtmlContent {
    ...HtmlNodeFragment
  }
  mobileImage {
    ...ImageFragment
  }
  mobileTextLine1
  mobileTextLine2
  name
  skin
  textLine1
  textLine2
  type
}
    `;