'use client';

import color from '@haaretz/l-color.macro';
import fork from '@haaretz/l-fork.macro';
import merge from '@haaretz/l-merge.macro';
import mq from '@haaretz/l-mq.macro';
import space from '@haaretz/l-space.macro';
import ClickArea from '@haaretz/s-click-area';
import Icon from '@haaretz/s-icon';
import Modal from '@haaretz/s-modal';
import VisuallyHidden from '@haaretz/s-visually-hidden';
import { useState } from 'react';
import s9 from 'style9';

import useRainbowBiAction from '../../../../utils/useRainbowBiAction';
import ActionButton from '../../ActionButton';
import EmailValidationActionButton from '../../ActionButton/EmailValidationActionButton';
import RainbowImpression from '../../RainbowImpression';

import type { StaticAttributes } from './RainbowModal';
import type { MultiTypeMarketingElementServerProps } from '../../';
import type { ActionButtonProps } from '../../ActionButton/ActionButton';

const c = s9.create({
  base: {
    backgroundColor: fork({ default: color('primary200'), tm: color('neutral300') }),
    color: color('neutral1200'),
    border: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
    '::backdrop': {
      '--bgc': color('rainbowModalMobileBackdrop', { opacity: fork({ default: 0.8, tm: 0.9 }) }),
      backgroundColor: 'var(--bgc)',
      animationName: s9.keyframes({
        '0%': { backgroundColor: 'transparent' },
        '100%': { backgroundColor: 'var(--bgc)' },
      }),
      animationPlayState: 'running',
      animationDuration: '0.25s',
      animationDirection: 'normal',
      animationFillMode: 'forwards',
      color: color('neutral1200'),
    },
    ...merge(
      mq({
        until: 's',
        value: {
          minHeight: space(90),
          marginBottom: 0,
          minWidth: '100%',
          borderTopLeftRadius: space(4),
          borderTopRightRadius: space(4),
          paddingTop: space(8),
          paddingBottom: space(13),
        },
      }),
      mq({
        from: 's',
        value: {
          width: space(125),
          minHeight: space(118),
          paddingTop: space(15),
          paddingBottom: space(15),
        },
      }),
      mq({
        from: 's',
        value: {
          borderTopLeftRadius: space(18),
          borderTopRightRadius: space(18),
          borderBottomLeftRadius: space(18),
          borderBottomRightRadius: space(18),

          '::backdrop': {
            '--bgc': color('rainbowModalBackdrop', { opacity: fork({ default: 0.8, tm: 0.75 }) }),
          },
        },
      })
    ),
  },
  closeButton: {
    position: 'absolute',
    maxWidth: space(2),
    maxHeight: space(2),
    padding: 0,
    margin: 0,
    top: space(1.5),
    color: color('neutral1200'),
    insetInlineEnd: space(1.5),
    ...merge(
      mq({
        from: 's',
        until: 'xl',
        value: {
          marginTop: space(6.5),
          insetInlineEnd: space(7.5),
        },
      }),
      mq({
        from: 'xl',
        value: {
          marginTop: space(8),
          insetInlineEnd: space(8),
        },
      })
    ),
  },
  closeIcon: {
    fontSize: space(5),
    color: color('neutral1200'),
    ...merge(
      mq({
        from: 's',
        value: {
          fontSize: space(6),
        },
      })
    ),
  },
  actionButton: {
    marginTop: space(6),
    ...merge(
      mq({
        from: 'xl',
        value: {
          paddingInlineStart: space(5),
          paddingInlineEnd: space(5),
        },
      })
    ),
  },
  onOpenKeyFrames: {
    '--trsfrm-a': 'translateY(120%)',
    '--trsfrm-b': 'translateY(0)',
    display: 'flex',
    animationName: s9.keyframes({
      '0%': { opacity: 0, transform: 'var(--trsfrm-a)' },
      '30%': { opacity: 0 },
      '100%': { opacity: 1, transform: 'var(--trsfrm-b)' },
    }),
    animationDuration: '0.5s',
    animationFillMode: 'forwards',
    animationTimingFunction: 'ease-in-out',
    opacity: 0,

    ...merge(
      mq({
        from: 's',
        value: {
          '--trsfrm-a': 'scale(0.7)',
          '--trsfrm-b': 'scale(1)',
        },
      })
    ),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    ...merge(
      mq({
        until: 's',
        value: {
          maxWidth: space(66),
        },
      }),
      mq({
        from: 's',
        until: 'xl',
        value: {
          width: space(86),
        },
      }),
      mq({
        from: 'xl',
        value: {
          width: space(112),
        },
      })
    ),
  },
});

type DynamicProps = Omit<MultiTypeMarketingElementServerProps, keyof StaticAttributes> & {
  labelledBy: string;
  children: React.ReactNode;
  modalText: React.JSX.Element[];
};

export default function RainbowModalClient({
  children,
  styleExtend = [],
  labelledBy,
  declineBehavior,
  declineCodeNumber,
  declineText,
  actionCodeNumber,
  actionText,
  actionName,
  actionUrl,
  actionType,
  modalText,
  featureName,
  featureType,
  skin,
  campaignName,
  campaignDetails,
  metaData,
  testGroup,
}: DynamicProps) {
  const [isOpen, setIsOpen] = useState(true);
  const forbidClosing = declineBehavior ? declineBehavior === 'none' : undefined;
  const actionButtonProps: ActionButtonProps | null = actionCodeNumber
    ? {
        autofocus: true,
        actionCodeNumber,
        actionText: actionText ?? undefined,
        actionUrl: actionUrl ?? undefined,
        actionType: actionType ?? undefined,
        featureName: featureName ?? undefined,
        featureType: featureType ?? undefined,
        featureTheme: skin ?? undefined,
        campaignName: campaignName ?? undefined,
        campaignDetails: campaignDetails ?? undefined,
        styleExtend: [c.actionButton],
      }
    : null;

  const ActionButtonComponent =
    actionName === 'ValidateEmailAction' ? EmailValidationActionButton : ActionButton;

  const doBiDecline = useRainbowBiAction({
    campaignDetails: campaignDetails ?? undefined,
    actionCodeNumber: declineCodeNumber ?? undefined,
    campaignName: campaignName ?? undefined,
    featureName: featureName ?? undefined,
    featureType: featureType ?? undefined,
    featureTheme: skin ?? undefined,
    metaData,
    testGroup,
  });

  function handleModalClose() {
    doBiDecline && doBiDecline();
  }

  return (
    <Modal
      labelledBy={labelledBy}
      isOpen={isOpen}
      onClose={handleModalClose}
      styleExtend={[c.base, isOpen && c.onOpenKeyFrames, ...styleExtend]}
      forbidClosing={forbidClosing}
    >
      <RainbowImpression
        featureName={featureName ?? undefined}
        featureType={featureType ?? undefined}
        featureTheme={skin ?? undefined}
        campaignName={campaignName ?? undefined}
        campaignDetails={campaignDetails ?? undefined}
        metaData={metaData}
        testGroup={testGroup}
      />
      {!forbidClosing ? (
        <ClickArea styleExtend={[c.closeButton]} onClick={() => setIsOpen(false)} aria-hidden>
          <Icon icon="close" styleExtend={[c.closeIcon]} />
          <VisuallyHidden>{declineText}</VisuallyHidden>
        </ClickArea>
      ) : null}
      <div className={s9(c.content)}>
        {children}
        {modalText}
        {actionButtonProps && (
          <ActionButtonComponent {...actionButtonProps} styleExtend={[c.actionButton]} />
        )}
      </div>
    </Modal>
  );
}
