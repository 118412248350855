'use server';

import getMarketingComponentByTypename from '../getMarketingComponentByTypename';

import type { RainbowToolFragment } from '@haaretz/s-fragments/RainbowTool';
import type { PlatformType, BaseServerActionParams, StyleExtend } from '@haaretz/s-types';

interface ToolFromServerType extends BaseServerActionParams {
  tool: RainbowToolFragment;
  platform: PlatformType;
  styleExtend?: StyleExtend;
}

const defaultResponse = null;

export default async function getToolFromServer({
  tool,
  platform,
  styleExtend,
}: ToolFromServerType) {
  try {
    const elementType = tool.element.__typename;
    if (elementType === 'OnboardingElement') return null;

    const Element = getMarketingComponentByTypename(elementType);

    return (
      <Element
        {...tool?.element}
        platform={platform}
        metaData={tool?.metaData}
        testGroup={tool?.testGroup}
        styleExtend={styleExtend}
      />
    );
  } catch (error) {
    console.error(`Rainbow server action failed: ${(error as Error).message}`);

    return defaultResponse;
  }
}
