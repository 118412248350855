'use client';

import ReactDOM from 'react-dom';

/**
 * Generate preconnect `<link>` elements
 */
export default function PreconnectLinks() {
  // Images and static assests domain
  ReactDOM.preconnect('https://img.haarets.co.il');

  return null;
}
