import * as Types from '@haaretz/s-fragments/Types';

export type BreakingNewsNotificationFragment = (
  { articleId?: string | null, creationDateTime?: any | null, isOffline: boolean, title?: string | null, kicker?: string | null, url?: string | null }
  & { __typename: 'BreakingNewsNotification' }
);


export type BreakingNewsNotificationFragmentVariables = Types.Exact<{ [key: string]: never; }>;

export const BreakingNewsNotificationFragmentGql = `
    fragment BreakingNewsNotificationFragment on BreakingNewsNotification {
  __typename
  articleId
  creationDateTime
  isOffline
  title
  kicker
  url
}
    `;